
import Arc from "./Geometry/Arc";
import Circle from "./Geometry/Circle";
import Line from "./Geometry/Line";
import Point from "./Geometry/Point";
import Polygon from "./Geometry/Polygon";
import Region from "./Geometry/Region";
import Insert from "./Geometry/Insert";


export type Geometry = Point | Line | Arc | Circle | Polygon | Region | Insert;

export enum GeometryType {
  Point = "POINT",
  Line = "LINE",
  Arc = "ARC",
  Circle = "CIRCLE",
  Polygon = "POLYGON",
  Region = "REGION",
  Insert = "INSERT"
}


export default Geometry;
