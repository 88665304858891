
import {useState} from "react";
import Transform from "../model/CSys/Transform";
import Coordinate from "../model/CSys/Coordinate";
import {ViewportSize} from "./useViewportSize";

export type Camera = ReturnType<typeof useCamera>;


export function useCamera(vp: ViewportSize) {
    const w = vp.width * window.devicePixelRatio;
    const h = vp.height * window.devicePixelRatio;

    const maxZoomRate = 50;
    const minZoomRate = 5;

    const [origin, setOrigin] = useState<[number, number]>([0, 0]);
    const [rot, setRotation] = useState<number>(0);
    const [zoom, setZoom] = useState<number>(1);
    const [zoomRate, setZoomRate] = useState(10);

    const toScreen: Transform = (p) => {
        let [x, y] = p;
        /*console.log({
            vp, p, rot, zoom, origin, o: [
                ((x * Math.cos(rot) - y * Math.sin(rot)) + origin[0]) / zoom + w/2,
                (((x * Math.sin(rot) + y * Math.cos(rot)) + origin[1]) / zoom) * -1 + h/2
            ]
        });*/

        let kx = x - origin[0]
        let ky = y - origin[1];

        let jx = (kx * Math.cos(rot) - ky * Math.sin(rot))
        let jy = (kx * Math.sin(rot) + ky * Math.cos(rot));

        return [
            jx / zoom + w / 2,
            (jy / zoom - h / 2) * -1
        ];
    }

    const toModel: Transform = (p) => {
        let [x, y] = p;

        // TODO: add rotation to screen-model transform
        return [
            ((x - w/2) * zoom) + origin[0],
            ((y - h/2) * zoom) + origin[1]
        ];
    }

    const rotate = (dx: number) => {
        setRotation(rot + (dx / w) * 10);
    }

    const pan = (dx: number, dy: number) => {
        let [x, y] = origin;

        setOrigin([
            x - dx * zoom,
            y + dy * zoom
        ]);
    }

    const goToOrigin = () => setOrigin([0, 0]);
    const goTo = (p: Coordinate) => setOrigin(p);
    const resetZoom = () => setZoom(1);
    const resetRot = () => setRotation(0);

    const zoomExtent = (min: Coordinate, max: Coordinate) => {

    }

    return {
        z: zoom,
        zoomRate,
        origin,
        goTo,
        goToOrigin,
        resetRot,
        resetZoom,
        zoomOut: (n: number = 1) => setZoom(Math.min(10000, zoom + (zoom / zoomRate) * n)),
        zoomIn: (n: number = 1) => setZoom(Math.max(0.001, zoom - (zoom / zoomRate) * n)),
        setZoomRate: (n: number = 10) => {
            //console.log(`zoom rate ${Math.min(maxZoomRate, Math.max(minZoomRate, n))}`);
            setZoomRate(Math.min(maxZoomRate, Math.max(minZoomRate, n)))
        },
        zoomFast: (n: number = 1) => setZoomRate(Math.min(maxZoomRate, zoomRate + .05 * n)),
        zoomSlow: (n: number = 1) => setZoomRate(Math.max(minZoomRate, zoomRate - .05 * n)),
        zoomExtent,
        rotate,
        pan,
        zoomAtPoint: (p: [number, number], n: number = 1, delta: [number, number] = [0,0]) => {
            let [x, y] = origin;
            let dx = p[0] - w / 2;
            let dy = p[1] - h / 2;

            setOrigin([x + dx * (zoom - zoom * n) - delta[0] * zoom * n, y - dy * (zoom - zoom * n) + delta[1] * zoom * n]);
            setZoom(zoom * n);
        },
        toScreen,
        toModel
    }
}

export default useCamera;