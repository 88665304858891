
import React from "react";
import styled from "styled-components";
import View from "./component/View";

const AppBox = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
`;


export function App() {
  return <AppBox>
    <View />
  </AppBox>;
}

export default App;
