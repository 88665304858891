
import Coordinate from "../model/CSys/Coordinate";
import Transform from "../model/CSys/Transform";
import {Camera} from "../hooks/useCamera";


export function drawGrid(ctx: CanvasRenderingContext2D, camera: Camera, increment: number = 10, minorColor: string, majorColor: string) {

    let {z} = camera;
    let baseIncrement = increment;
    let pixel = increment / z;
    while (pixel < 20) {
        increment = increment * 5;
        pixel = increment / z;
    }

    while (pixel > 100) {
        increment = increment / 5;
        pixel = increment / z;
    }

    drawGridLayer(ctx, camera, increment, minorColor);
    drawGridLayer(ctx, camera, increment * 10, majorColor);
}

export function drawGridLayer(ctx: CanvasRenderingContext2D, camera: Camera, increment: number = 10, color: string) {
    ctx.lineWidth = 1;
    ctx.strokeStyle = color;

    let {toScreen, toModel} = camera;

    let box = getCameraBounding(ctx, toModel);
    let xr = xRange(box);
    let yr = yRange(box);

    for (let x = Math.floor(xr.min / increment) * increment; x <= Math.ceil(xr.max / increment) * increment; x += increment) {
        ctx.beginPath();
        ctx.moveTo(...toScreen([x, yr.min]));
        ctx.lineTo(...toScreen([x, yr.max]));
        ctx.stroke();
    }

    for (let y = Math.floor(yr.min / increment) * increment; y <= Math.ceil(yr.max / increment) * increment; y += increment) {
        ctx.beginPath();
        ctx.moveTo(...toScreen([xr.min, y]));
        ctx.lineTo(...toScreen([xr.max, y]));
        ctx.stroke();
    }
}

function getCameraBounding(ctx: CanvasRenderingContext2D, toModel: Transform): Coordinate[] {
    let w = ctx.canvas.width;
    let h = ctx.canvas.height;

    return [
        toModel([0, 0]),
        toModel([0, h]),
        toModel([w, 0]),
        toModel([w, h])
    ]
}

function xRange(points: Coordinate[]): {min: number, max: number} {
    return {
        min: Math.min(...points.map(p => p[0])),
        max: Math.max(...points.map(p => p[0]))
    }
}

function yRange(points: Coordinate[]): {min: number, max: number} {
    return {
        min: Math.min(...points.map(p => p[1])),
        max: Math.max(...points.map(p => p[1]))
    }
}


export default drawGrid;
