
import {CanvasSize} from "./useCanvasSize";

export type ViewportSize = {
    width: number,
    height: number
}

export function useViewportSize(canvas: CanvasSize): ViewportSize {
    return {
        width: canvas.width / window.devicePixelRatio,
        height: canvas.height / window.devicePixelRatio
    };
}

export default useViewportSize;
