
import Coordinate from "../model/CSys/Coordinate";


export function centerOfBox(a: Coordinate, b: Coordinate): Coordinate {
  return [
    (b[0] - a[0]) / 2 + a[0],
    (b[1] - a[1]) / 2 + a[1]
  ];
}

export default centerOfBox;
