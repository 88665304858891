
type CursorType = "Cross" | "Circle";

export function drawCursor(ctx: CanvasRenderingContext2D, type: CursorType, loc: [number, number], color: string) {
    let w = ctx.canvas.width;
    let h = ctx.canvas.height;
    let [x, y] = loc;
    ctx.lineWidth = 1;
    ctx.globalAlpha = 1;
    ctx.strokeStyle = color;

    switch (type) {
        case "Circle":
            ctx.beginPath();
            ctx.arc(x, h - y, 50, 0, Math.PI * 2);
            ctx.stroke();
            break;
        case "Cross":
            ctx.beginPath();
            ctx.moveTo((x - 25), h - y);
            ctx.lineTo((x + 25), h - y);
            ctx.moveTo(x, h - (y - 25));
            ctx.lineTo(x, h - (y + 25));
            ctx.stroke();
            break
    }
}

export default drawCursor;
