
import {Camera} from "../hooks/useCamera";
import Coordinate from "../model/CSys/Coordinate";

export function drawSelectBox(ctx: CanvasRenderingContext2D, camera: Camera, box: [Coordinate, Coordinate], stroke: string = '#ff0000', fill: string = 'rgba(255, 255, 255, 0.2)') {
  let [start, end] = box.map(p => camera.toScreen(p));

  ctx.fillStyle = fill;
  ctx.strokeStyle = stroke;
  ctx.beginPath();
  ctx.rect(...start, end[0] - start[0], end[1] - start[1]);
  ctx.fill();
  ctx.stroke();
}

export default drawSelectBox;
