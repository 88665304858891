
import {CsStack} from "../lib/CsStack";
import Object from "../model/Object";
//import StyleSet from "../lib/StyleSet";
//import Style from "../lib/Style";
import Line from "../model/Geometry/Line";
import {Camera} from "../hooks/useCamera";
import {GeometryType} from "../model/Geometry";
import CsTransform from "../lib/CsTransform";
import Point from "../model/Geometry/Point";


export function render(draw: CanvasRenderingContext2D, cam: Camera, root: Object, styleSet?: any, blocks: {[key: string]: Object} = {}, active?: string) {

  // Coordinate System
  // Provides coordinate mapping to/from the calling context.
  const cs: CsStack = new CsStack();

  // Tracks position in the object tree.
  const ctx: string[] = [];

  renderObject(draw, cam, cs, ctx, root, styleSet, blocks, active);
}

export function renderObject(draw: CanvasRenderingContext2D, cam: Camera, cs: CsStack, ctx: string[], o: Object,  styleSet?: any, blocks: {[key: string]: Object} = {}, active?: string) {

  // update context
  ctx.push(o.name);

  // move into object coordinate space
  if (o.cs != null) {
    let nextCs = new CsTransform();
    nextCs.origin = o.cs.origin;
    nextCs.rot = o.cs.rotation;
    nextCs.scale = o.cs.scale;
    cs.push(nextCs);
  }

  if (typeof o.content == "string") {
    if (blocks[o.content] == null) {
      console.error(`missing block ${o.content}`);
    } else {
      renderObject(draw, cam, cs, ctx, blocks[o.content], styleSet, blocks, active);
    }
  } else {
    for (let i of o.content) {
      switch (i.type) {
        case "Object":
          renderObject(draw, cam, cs, ctx, i, styleSet, blocks, active);
          break;
        case GeometryType.Line:
          renderLine(draw, cam, cs, i);
          break;
        case GeometryType.Point:
          renderPoint(draw, cam, cs, i);
          break;
      }
    }
  }

  // restore context
  ctx.pop();
  cs.pop();
}


function renderLine(draw: CanvasRenderingContext2D, cam: Camera, cs: CsStack, o: Line, selected: boolean = false, color: string = '#ffffff', opacity: number = 1) {
  // console.log(`line ${cam.toScreen(cs.toWorld(o.start))}, ${cam.toScreen(cs.toWorld(o.end))}`);
  draw.globalAlpha = opacity;
  draw.strokeStyle = color;
  draw.beginPath();
  draw.moveTo(...cam.toScreen(cs.toWorld(o.start)));
  draw.lineTo(...cam.toScreen(cs.toWorld(o.end)))
  draw.stroke();
}
function renderPoint(draw: CanvasRenderingContext2D, cam: Camera, cs: CsStack, o: Point, selected: boolean = false, color: string = '#ffffff', opacity: number = 1) {
  // console.log(`line ${cam.toScreen(cs.toWorld(o.start))}, ${cam.toScreen(cs.toWorld(o.end))}`);
  let [x, y] = cam.toScreen(cs.toWorld(o.location));

  draw.globalAlpha = opacity;
  draw.strokeStyle = color;
  draw.beginPath();
  draw.moveTo(x - 10, y);
  draw.lineTo(x + 10, y);
  draw.moveTo(x, y - 10);
  draw.lineTo(x, y + 10);
  draw.stroke();
}



export default renderObject;
