
export function drawMarks(ctx: CanvasRenderingContext2D, color: string) {
    let w = ctx.canvas.width;
    let h = ctx.canvas.height;

    ctx.lineWidth = 1;
    ctx.strokeStyle = color;

    ctx.beginPath()
    ctx.moveTo(10, 35);
    ctx.lineTo(10,10);
    ctx.lineTo(35, 10);

    ctx.moveTo(w - 10, 35);
    ctx.lineTo(w - 10,10);
    ctx.lineTo(w - 35, 10);

    ctx.moveTo(10, h - 35);
    ctx.lineTo(10,h - 10);
    ctx.lineTo(35, h - 10);

    ctx.moveTo(w - 10, h - 35);
    ctx.lineTo(w - 10, h - 10);
    ctx.lineTo(w - 35, h - 10);

    ctx.moveTo(Math.floor(w / 2) - 10, Math.floor(h / 2));
    ctx.lineTo(Math.floor(w / 2) + 10, Math.floor(h / 2));

    ctx.moveTo(Math.floor(w / 2), Math.floor(h / 2) - 10);
    ctx.lineTo(Math.floor(w / 2), Math.floor(h / 2) + 10);

    ctx.stroke();
}

export default drawMarks;
