
import Coordinate from "../model/CSys/Coordinate";
import Object from "../model/Object";
import {GeometryType} from "../model/Geometry";


type Range = { min: null | number, max: null | number };

class BoundingBox
{
  x: Range = { min: null, max: null }
  y: Range = { min: null, max: null }

  push(...points: Coordinate[]) {
    for (let p of points) {
      if (this.x.min == null) {
        this.x.min = p[0];
      } else {
        this.x.min = Math.min(this.x.min, p[0]);
      }

      if (this.x.max == null) {
        this.x.max = p[0];
      } else {
        this.x.max = Math.max(this.x.max, p[0]);
      }

      if (this.y.min == null) {
        this.y.min = p[1];
      } else {
        this.y.min = Math.min(this.y.min, p[1]);
      }

      if (this.y.max == null) {
        this.y.max = p[1];
      } else {
        this.y.max = Math.max(this.y.max, p[1]);
      }
    }
  }

  toPoints(): [Coordinate, Coordinate]
  {
    return [
      [this.x.min ?? 0, this.y.min ?? 0],
      [this.x.max ?? 0, this.y.max ?? 0],
    ]
  }

}

export function boundingBox(o?: Object, box?: BoundingBox) {
  if (box == null) box = new BoundingBox();
  if (o == null) return box.toPoints();

  if (typeof o.content === "string") {
    // TODO: include object refs in bounding box
    return box.toPoints()
  }

  for (let i of o.content) {
    switch (i.type) {
      case "Object":
        boundingBox(i, box);
        break;
      case GeometryType.Line:
        box.push(i.start, i.end);
        break;
    }
  }

  //console.log(box.toPoints());
  return box.toPoints()
}

export default boundingBox;
