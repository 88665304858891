
import {RefObject, useEffect, useState} from "react";
import useResizeObserver from "@react-hook/resize-observer";

export type CanvasRef = RefObject<HTMLCanvasElement>;
export type CanvasSize = {
  offsetLeft: number,
  offsetTop: number,
  width: number,
  height: number,
}

export function useCanvasSize(ref: CanvasRef): CanvasSize {

  const [refresh, setRefresh] = useState(0);
  const [size, setSize] = useState<CanvasSize>({
    offsetLeft: 0,
    offsetTop: 0,
    width: ref?.current?.width ?? 300,
    height: ref?.current?.height ?? 300,
  });

  useEffect(() => {
    const canvas = ref.current;
    if (canvas == null) return;

    setSize({
      offsetLeft: canvas.offsetLeft,
      offsetTop: canvas.offsetTop,
      width: canvas.offsetWidth,
      height: canvas.offsetHeight
    });
  }, [refresh, ref]);

  useResizeObserver<HTMLCanvasElement>(
    ref,
    (entry) => setRefresh(refresh + 1)
  );

  return size;
}

export default useCanvasSize;
