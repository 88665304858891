import {useEffect, useState} from "react";

type WindowSize = {
  width: number|null,
  height: number|null
}

const initWindowSize: WindowSize = {
  width: null,
  height: null
}


export function useWindowSize() {

  const [size, setSize] = useState(initWindowSize);

  useEffect(() => {

    const resize = () => setSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    resize();
    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener('resize', resize);
    }

  }, []);

  return size;
}

export default useWindowSize;
