
import React, {MouseEventHandler, useEffect, useRef, useState, WheelEventHandler} from "react";
// @ts-ignore
import { useKey, useKeyCombo } from "@rwh/react-keystrokes";

import useCanvasSize from "../hooks/useCanvasSize";
import useFpsCounter from "../hooks/useFpsCounter";
import useViewportSize from "../hooks/useViewportSize";

import Object from "../model/Object";
import drawMarks from "../draw/drawMarks";
import drawFpsCounter from "../draw/drawFpsCounter";
import drawGrid from "../draw/drawGrid";
import {Camera, useCamera} from "../hooks/useCamera";
import Coordinate from "../model/CSys/Coordinate";
import drawSelectBox from "../draw/drawSelectBox";
import render from "../draw/render";
import {GeometryType} from "../model/Geometry";
import drawCursor from "../draw/drawCursor";
import {CsStack} from "../lib/CsStack";
import {CsTransform} from "../lib/CsTransform";
import Prompt, {PromptType} from "../model/Prompt";
import boundingBox from "../lib/boundingBox";
import centerOfBox from "../lib/centerOfBox";
import styled from "styled-components";
import Tray from "./Tray";


const colors = {
  background: "#0C0C0C",
  red: "#AC482E",
  yellow: "#F6FEC9",
  blue1: "#2B383E",
  blue2: "#3A5659",
  blue3: "#47909A",
  blue4: "#96E0EC",
  selectEdge: "#F6FEC9",
  selectFill: "rgba(246, 254, 201, 0.1)"
}



export type ViewProps = {
  dpi?: number,
  obj?: Object,
  blocks?: { [key: string]: Object }
  prompt?: Prompt
  onCancel?: () => any
}


function toggle(flag: boolean): string {
  return flag ? 'on' : "off";
}


type Cursor = {
  type: "point" | "move" | "rotate" | "pivot"
  screen: [number, number],
  local: [number, number],
  global: [number, number],
}

type T2 = { pitch: number, length: number, centre: [number, number] };



function dist(p1: [number, number], p2: [number, number]): number {
  return Math.sqrt(
    Math.pow((p1[0] - p2[0]), 2)
    + Math.pow((p1[1] - p2[1]), 2)
  );
}

function angle(p1: [number, number], p2: [number, number]): number {
  let x = p1[0] - p2[0];
  let y = p1[1] - p2[1];

  let a = Math.atan2(x, y);
  if (a < 0) a = (Math.PI - Math.abs(a)) + Math.PI;

  return a;
}

function centre(p1: [number, number], p2: [number, number]): [number, number] {
  return [
    p1[0] - (p1[0] - p2[0]) / 2,
    p1[1] - (p1[1] - p2[1]) / 2,
  ];
}

function xyDelta(p1: [number, number], p2: [number, number]): [number, number] {
  return [
    p1[0] - p2[0],
    p1[1] - p2[1],
  ];
}


const touchListToMap = (touches: TouchList): {[key: string]: Touch} => {
  let n: {[key: string]: Touch} = {};
  for (let i = 0; i < touches.length; i+=1) {
    n[touches[i].identifier] = touches[i];
  }
  return n;
}
const touchListToArray = (touches: TouchList): Touch[] => {
  let n: Touch[] = [];
  for (let i = 0; i < touches.length; i+=1) {
    n.push(touches[i]);
  }
  return n;
}


const calcT2 = (ts: Touch[]): T2 => {
  let t1: [number, number] = [ts[0].clientX, ts[0].clientY];
  let t2: [number, number] = [ts[1].clientX, ts[1].clientY];

  return {
    pitch: angle(t1, t2),
    length: dist(t1, t2),
    centre: centre(t1, t2)
  }
}




export function View(props: ViewProps) {
  let {
    obj,
    blocks = {},
    prompt = null,
    onCancel = null
  } = props;


  const canvasRef = useRef<HTMLCanvasElement>(null);
  let bbox = boundingBox(obj)

  // Settings
  const [zoomStep, setZoomStep] = useState<string>("10");
  const [grid, toggleGrid] = useState(true);
  const [marks, toggleMarks] = useState(true);
  const [counter, toggleCounter] = useState(true);

  // Canvas
  const fps = useFpsCounter();
  const screen = useCanvasSize(canvasRef);

  const viewport = useViewportSize(screen);
  const camera = useCamera(viewport);
  useEffect(() => {
    camera.setZoomRate(parseInt(zoomStep));
  }, [zoomStep]);

  // Position in Model
  const [focus, setFocus] = useState<string[]>([]);

  const [inputs, setInputs] = useState();

  // Coordinate tracking
  const [cursorScreen, setCursorScreen] = useState([0, 0]);
  const [cursorLocal, setCursorLocal] = useState([0, 0]);
  const [cursorSpace, setCursorSpace] = useState([0, 0]);

  const pressEscape = useKey('Escape');
  const pressA = useKey('a');

  // Coordinate System
  // Provides coordinate mapping to/from the calling context.
  const cs: CsStack = new CsStack();

  if (obj != null) {
    let o: any = obj;
    for (let oid of focus) {
      for (let i of o.content) {
        if ((i as any).id == oid) {
          o = i;
          let t = new CsTransform();
          if (o.cs != null) {
            t.origin = o.cs.origin
            t.scale = o.cs.scale
            t.rot = o.cs.rotation
          }
          cs.push(t);
        }
      }
    }
  }

  if (pressEscape && onCancel != null) onCancel();
  if (pressA) console.log(`a`);



  // Input
  //const [pan, setPan] = useState(false);
  const [selectBox, setSelectBox] = useState<null | [[number, number], [number, number]]>(null);

  const [pCache, setPCache] = useState<{[key: string]: PointerEvent}>({});
  const [t2Cache, setT2Cache] = useState<T2|null>(null);

  const mouseDown: (ev: PointerEvent) => any = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();

    // ignore touch events, use touch events instead
    if (ev.pointerType === "touch") return;

    canvasRef.current?.setPointerCapture(ev.pointerId);

    let n = Object.assign({}, pCache);
    n[ev.pointerId] = ev;
    setPCache(n);

    /*
    if (ev.button == 0) {
      canvasRef.current?.setPointerCapture(ev.pointerId);
      let pos: Coordinate = [ev.clientX - screen.offsetLeft, screen.height - ev.clientY + screen.offsetTop];
      let point = camera.toModel(pos);
      if (prompt == null) {
        setSelectBox([point, point]);
        return;
      }
      switch (prompt.type) {
        case PromptType.Coordinate:
          prompt.cb(point);
          break;
      }
    }
    if (ev.button == 1) {
      canvasRef.current?.setPointerCapture(ev.pointerId);
      //setPan(true);
    }*/
  }

  const mouseUp: (ev: PointerEvent) => any = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();

    // ignore touch events, use touch events instead
    if (ev.pointerType === "touch") return;

    canvasRef.current?.releasePointerCapture(ev.pointerId);

    let n = Object.assign({}, pCache);
    delete n[ev.pointerId];
    setPCache(n);

    /*
    if (ev.button == 0) {
      canvasRef.current?.releasePointerCapture(ev.pointerId);
      setSelectBox(null);
    }
    if (ev.button == 1) {
      canvasRef.current?.releasePointerCapture(ev.pointerId);
      //setPan(false);
    }
    */
  }

  // TODO: ignore touch pointer events - replace with touch event handlers

  const mouseOut: (ev: PointerEvent) => any = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();

    // ignore touch events, use touch events instead
    if (ev.pointerType === "touch") return;

    //setSelectBox(null);
    //setPan(false);
  }

  const mouseMove: (ev: PointerEvent) => any = (ev) => {
    //console.log(`pointer ${ev.pointerId} ${ev.button}`);
    //console.log(`${screen.height} :: ${ev.clientY} :: ${screen.offsetTop}`);
    ev.stopPropagation();
    ev.preventDefault();

    // ignore touch events, use touch events instead
    if (ev.pointerType === "touch") return;

    let nCache = Object.assign({}, pCache);
    nCache[ev.pointerId] = ev;

    let posScreen: [number, number] = [ev.clientX - screen.offsetLeft, screen.height - (ev.clientY - screen.offsetTop)];
    let posWorld = camera.toModel(posScreen);
    let posLocal = cs.toLocal(posWorld);

    setCursorScreen(posScreen);
    setCursorSpace(posWorld);
    setCursorLocal(posLocal);

    switch (ev.pointerType) {
      case "mouse":
        let mx: PointerEvent|undefined = pCache[ev.pointerId];

        // mouse movements are interpreted based on the active button at the
        // time of movement. Mouse buttons have a strict hierarchy, which
        // determines how multiple button inputs are interpreted.
        // primary button = add to selection / remove from selection (shift)
        // secondary button =
        // auxiliary button = move / rotate (shift)

        if ((ev.buttons & 1) == 1) {

        } else if ((ev.buttons & 4) == 4) {
          if (ev.shiftKey) {
            // rotation is disabled for now
            // camera.rotate(ev.movementX);
          } else {
            camera.pan(ev.movementX, ev.movementY);
          }
        }


        break;
      case "pen":
        let px: PointerEvent|undefined  = pCache[ev.pointerId];

        break;
      case "touch":
        let ti = Object.values(pCache).filter(p => p.pointerType === "touch");
        let tk = Object.values(nCache).filter(p => p.pointerType === "touch");

        break;
    }

    setPCache(nCache);
  }

  const mouseScroll: WheelEventHandler = (e) => {
    e.preventDefault();
    e.nativeEvent.preventDefault();
    e.nativeEvent.stopImmediatePropagation();

    camera.zoomAtPoint([e.clientX, e.clientY], 1 + ( e.deltaY > 0 ? 0.05 : - 0.05 ));
  }

  const [tCache, setTCache] = useState<{[key: string]: Touch}>({});



  const touchStart: (ev: TouchEvent) => any = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    ev.stopImmediatePropagation();
    setTCache(touchListToMap(ev.touches))
    let touches = touchListToArray(ev.touches);

    if (touches.length == 2) {
      let t2 = calcT2(touches);
      setT2Cache(t2);
    }
  };

  const touchMove: (ev: TouchEvent) => any = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    ev.stopImmediatePropagation();
    setTCache(touchListToMap(ev.touches))
    let touches = touchListToArray(ev.touches);

    if (ev.touches.length == 2) {
      let t2 = calcT2(touches);

      if (t2Cache != null) {
        let ld = t2.length - t2Cache.length;
        let cd = xyDelta(t2.centre, t2Cache.centre);

        camera.zoomAtPoint(t2.centre, 1 - (ld / t2Cache.length), cd);
      }

      setT2Cache(t2);
    } else {
      setT2Cache(null);
    }
  };

  const touchEnd: (ev: TouchEvent) => any = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    ev.stopImmediatePropagation();
    setTCache({});
    setT2Cache(null);
  };


  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas == null) return;

    canvas.addEventListener('pointerdown', mouseDown, { passive: false });
    canvas.addEventListener('pointermove', mouseMove, { passive: false });
    canvas.addEventListener('pointerup', mouseUp, { passive: false });
    canvas.addEventListener('pointerout', mouseOut, { passive: false });
    canvas.addEventListener('touchstart', touchStart, { passive: false });
    canvas.addEventListener('touchmove', touchMove, { passive: false });
    canvas.addEventListener('touchend', touchEnd, { passive: false });
    canvas.addEventListener('touchcancel', touchEnd, { passive: false });

    return () => {
      canvas.removeEventListener('pointerdown', mouseDown);
      canvas.removeEventListener('pointermove', mouseMove);
      canvas.removeEventListener('pointerup', mouseUp);
      canvas.removeEventListener('pointerout', mouseOut);
      canvas.removeEventListener('touchstart', touchStart);
      canvas.removeEventListener('touchmove', touchMove);
      canvas.removeEventListener('touchend', touchEnd);
      canvas.removeEventListener('touchcancel', touchEnd);
    }
  }, [canvasRef, camera, screen, pCache, tCache, t2Cache]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas == null) return;

    const ctx = canvas.getContext('2d');
    if (ctx == null) return;

    const scale = window.devicePixelRatio;
    ctx.canvas.width = Math.floor(viewport.width * scale);
    ctx.canvas.height = Math.floor(viewport.height * scale);

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    if (grid) drawGrid(ctx, camera, 10, colors.blue1, colors.blue2);
    if (counter) drawFpsCounter(ctx, fps, colors.blue4);

    ctx.lineWidth = 1;
    ctx.strokeStyle = '#eee';

    if (obj != null) {
      render(ctx, camera, cs, focus, obj, undefined, blocks);
    }

    if (selectBox != null) drawSelectBox(ctx, camera, selectBox, colors.selectEdge, colors.selectFill);
    if (marks) drawMarks(ctx, colors.blue4);

    Object.values(pCache).filter(p => p.pointerType == 'mouse').map(ev => {
      drawCursor(ctx, "Cross", [ev.clientX - screen.offsetLeft, screen.height - (ev.clientY - screen.offsetTop)], colors.blue3);
    });

    Object.values(pCache).filter(p => p.pointerType == 'pen').map(ev => {
      drawCursor(ctx, "Cross", [ev.clientX - screen.offsetLeft, screen.height - (ev.clientY - screen.offsetTop)], colors.blue3);
    });

    Object.values(tCache).map(ev => {
      drawCursor(ctx, "Circle", [ev.clientX - screen.offsetLeft, screen.height - (ev.clientY - screen.offsetTop)], colors.blue3);
    });

    if (t2Cache !== null) {
      drawCursor(ctx, "Cross", [t2Cache.centre[0] - screen.offsetLeft, screen.height - (t2Cache.centre[1] - screen.offsetTop)], colors.blue3);
    }

  }, [screen, viewport, camera, fps, pCache, tCache])

  return <ViewBox>
    <Canvas
      ref={canvasRef}
      onWheel={mouseScroll}
  />
    <Status>
      <RescueGroup align="start" direction={"up"}>
        <button onClick={() => {
          camera.goToOrigin();
          camera.resetZoom();
          camera.resetRot();
        }}>origin</button>
        <button onClick={() => {
          camera.goTo(centerOfBox(...bbox));
        }}>extent</button>
      </RescueGroup>
      <CoordinateGroup align="center" direction={"up"}>
        <span style={{ padding: "0 1em", fontSize: "0.8em", minWidth: "120px" }}>Local: {Math.floor(cs.toLocal(camera.origin)[0])}, {Math.floor(cs.toLocal(camera.origin)[1])}</span>
        <span style={{ padding: "0 1em", fontSize: "0.8em", minWidth: "120px" }}>Zoom: {Math.floor(camera.z * 1000) / 1000}</span>
        <span style={{ padding: "0 1em", fontSize: "0.8em", minWidth: "120px" }}>Global: {Math.floor(camera.origin[0])}, {Math.floor(camera.origin[1])}</span>
        <span style={{ padding: "0 1em", fontSize: "0.8em", minWidth: "120px" }}>Screen: {Math.floor(camera.toScreen(camera.origin)[0])}, {Math.floor(camera.toScreen(camera.origin)[1])}</span>
      </CoordinateGroup>
      <SettingsGroup align="end" direction={"up"}>
        <button className={toggle(grid)} onClick={() => toggleGrid(!grid)}>grid</button>
        <button className={toggle(counter)} onClick={() => toggleCounter(!counter)}>fps</button>
        <button className={toggle(marks)} onClick={() => toggleMarks(!marks)}>marks</button>
      </SettingsGroup>
    </Status>
  </ViewBox>
}

const ViewBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Canvas = styled.canvas`
  width: 100%;
  height: 0;
  flex-grow: 1;
  background-color: #0C0C0C;
  cursor: none;
`;

const Status = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #354A50;
  padding: 0 1em;
  height: 2em;
  flex-shrink: 0;
  flex-grow: 0;
`;

const RescueGroup = styled(Tray)`
  display: flex;
  width: 30%;
  height: 100%;
  justify-content: start;
  align-items: center;
`;

const CoordinateGroup = styled(Tray)`
  display: flex;
  width: 40%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const SettingsGroup = styled(Tray)`
  width: 30%;
  
  .off {
    opacity: 0.5;
  }
`;


export default View;
