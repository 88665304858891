
import Coordinate from "../model/CSys/Coordinate";
import CsTransform from "./CsTransform";


export class CsStack
{

  stack: CsTransform[] = [];

  push(cs: CsTransform) {
    this.stack.push(cs);
  }

  pop(): CsTransform | undefined {
    return this.stack.pop();
  }

  reset() {
    this.stack = [];
  }

  toWorld(p: Coordinate): Coordinate {
   let n = p;
   for (let cs of this.stack.reverse()) {
     n = cs.toWorld(n);
   }
   return n;
  }

  toLocal(p: Coordinate): Coordinate {
    let n = p;
    for (let cs of this.stack) {
      n = cs.toLocal(n);
    }
    return n;
  }

}

export default CsTransform;
