
import {useEffect, useState} from "react";


export function useFpsCounter() {
    const [fps, setFPS] = useState(0);

    useEffect(() => {
        let start = performance.now();
        let counter = 0;
        const handleRender = () => {
            let c = performance.now();

            if (c > start + 1000) {
                setFPS(counter);
                start = c;
                counter = 1;
            } else {
                counter += 1;
            }
            window.requestAnimationFrame(handleRender);
        }
        window.requestAnimationFrame(handleRender);
    }, []);

    return fps;
}

export default useFpsCounter;
