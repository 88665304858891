
import Coordinate from "../model/CSys/Coordinate";


function toDegrees (angle: number) {
  return angle * (180 / Math.PI);
}

function toRadians (angle: number) {
  return angle * (Math.PI / 180);
}

export class CsTransform
{

  origin: [number, number] = [0, 0];
  rot: number = 0;
  scale: [number, number] = [1, 1]

  reset() {
    this.origin = [0, 0];
    this.rot = 0;
    this.scale = [1, 1];
  }

  // TODO: add rotation

  toWorld(p: Coordinate): Coordinate {
    let [x, y] = p;
    let rot = toRadians(this.rot);
    let [sx, sy] = this.scale;
    let [ox, oy] = this.origin;

    // Scale
    x = x * sx;
    y = y * sy;

    // Rotate
    let x0 = x, y0 = y;
    x = x0 * Math.cos(rot) - y0 * Math.sin(rot);
    y = x0 * Math.sin(rot) + y0 * Math.cos(rot);

    // Translate
    x = x + ox;
    y = y + oy;

    return [x, y];
  }

  toLocal(p: Coordinate): Coordinate {
    let [x, y] = p;
    let rot = 360 - this.rot;
    let [sx, sy] = this.scale;
    let [ox, oy] = this.origin;

    // Translate
    x = x - ox;
    y = y - oy;

    // Rotate
    //x = x * Math.cos(rot) - y * Math.sin(rot);
    //y = x * Math.sin(rot) + y * Math.cos(rot);

    // Scale
    x = x / sx;
    y = y / sy;

    return [x, y];
  }

}

export default CsTransform;
