

export function drawFpsCounter(ctx: CanvasRenderingContext2D, fps: number, color: string) {
    ctx.fillStyle = color;
    ctx.font = "18px sans-serif";
    ctx.fillText(fps.toString(), 30, 42);
}


export default drawFpsCounter;
